import React, {useCallback, useMemo, useState} from "react";
import {useDropzone} from "react-dropzone";
import {Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, Typography} from "@mui/material";
import {ClosableDialogTitle, TabBar, TabPanel} from "@atttomyx/react-components";
import {AddDocumentForm, VideoForm} from "../forms";
import {documentService, videoService} from "../../services";
import {arrays} from "@atttomyx/shared-utils";
import "./addDocumentDialog.css";

const freshData1 = () => {
    return {
        courseId: null,
        documentId: null,
        valid: false,
    }
};

const freshData2 = () => {
    return {
        title: null,
        youTubeId: null,
        valid: false,
    }
};

const AddDocumentDialog = (props) => {
    const { snackbar, courseId, courses, documents, onCancel, onSaveDocument, onSaveVideo } = props;
    const [ data1, setData1 ] = useState(freshData1());
    const [ data2, setData2 ] = useState(freshData2());
    const [ file, setFile ] = useState(null);
    const [ saving, setSaving ] = useState(false);
    const [ tab, setTab ] = useState(0);

    const coursesToUse = useMemo(() => {
        return courses.filter(course => course.id !== courseId);
    }, [courseId, courses]);

    const submitForm = () => {
        setSaving(true);

        const successDocument = (saved) => {
            snackbar.setSuccess(saved.fileName + " saved");
            setSaving(false);
            onSaveDocument(saved);
        };

        const successVideo = (saved) => {
            snackbar.setSuccess(saved.title + " saved");
            setSaving(false);
            onSaveVideo(saved);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        if (tab === 0) {
            documentService.uploadFile(courseId, file, successDocument, failure);

        } else if (tab === 1) {
            const document = arrays.findEntity(documents, data1.documentId);

            if (document) {
                documentService.addToCourse(courseId, document, successDocument, failure);

            } else {
                failure("File not found");
            }

        } else if (tab === 2) {
            const video = {
                title: data2.title,
                youTubeId: data2.youTubeId,
                courseIds: [courseId],
            };

            videoService.createVideo(video, successVideo, failure);
        }
    };

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length === 1) {
            const file = acceptedFiles[0];

            setFile(file);
        }
    }, [courseId]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: onDrop,
        maxFiles: 1,
    });

    return <Dialog
        open={true}
        aria-labelledby="add-document-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Add File
        </ClosableDialogTitle>
        <DialogContent className="add-document-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <>
                    <TabBar
                        tabs={["Upload", "Copy", "YouTube"]}
                        value={tab}
                        onChange={setTab}
                    />
                    <TabPanel value={tab} index={0}>
                        <Typography variant="caption">
                            Upload new file
                        </Typography>
                        {file ?
                            <div className="file">
                                <Chip label={file.path} variant="outlined" onDelete={() => setFile(null)} />
                            </div> :
                            <div {...getRootProps()} className="dnd-file">
                                <input {...getInputProps()} />
                                {isDragActive ?
                                    <p>Drop the file here</p> :
                                    <><p>Drag & drop a file here,</p><p>or click to select</p></>}
                            </div>}
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <Typography variant="caption">
                            Copy file from another course
                        </Typography>
                        <AddDocumentForm
                            courses={coursesToUse}
                            documents={documents}
                            data={data1}
                            onChange={setData1}
                        />
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <Typography variant="caption">
                            Attach a YouTub video
                        </Typography>
                        <VideoForm
                            data={data2}
                            onChange={setData2}
                        />
                    </TabPanel>
                </>}
        </DialogContent>
        {!saving ? <DialogActions>
            <Button
                color="primary"
                disabled={tab === 0 ? !file : tab === 1 ? !data1.valid : tab === 2 ? !data2.valid : true}
                onClick={submitForm}
            >
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default AddDocumentDialog;
