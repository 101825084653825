import React from 'react';
import {Card, CardActions, CardContent} from "@mui/material";
import {CardData, CardTitle} from "@atttomyx/react-components";
import {datetime} from "@atttomyx/shared-utils";

const VideoCard = (props) => {
    const { video, filters, children } = props;

    return <Card className="video-card" raised={true}>
        <CardContent>
            <CardTitle>
                {video.title}
            </CardTitle>
            <CardData
                filters={filters}
                data={video}
                keyToMetadata={{
                    youTubeId: {
                        title: "YouTube Id",
                    },
                    created: {
                        title: "Created",
                        formatter: datetime.getFullDateAndTime,
                    },
                    updated: {
                        title: "Updated",
                        formatter: datetime.getFullDateAndTime,
                    },
                }}
            />
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
}

export default VideoCard;
