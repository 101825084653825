import React, {useEffect, useMemo, useState} from "react";
import {Prompt} from "react-router-dom";
import fileDownload from "js-file-download";
import {Button, CircularProgress, Divider, IconButton} from "@mui/material";
import {
    Delete as DeleteIcon,
    Download as DownloadIcon,
    NavigateBefore as PrevIcon,
    NavigateNext as NextIcon,
    YouTube as WatchIcon
} from "@mui/icons-material";
import {usePaging} from "@atttomyx/shared-hooks";
import {
    Cards,
    ConfirmDeleteDialog,
    FloatingAddButton,
    VerticalDivider,
    YouTubeDialog
} from "@atttomyx/react-components";
import {CourseForm} from "../forms";
import {DocumentCard, VideoCard} from "../cards";
import {AddDocumentDialog} from "../dialogs";
import {courseService, documentService, videoService} from "../../services";
import {arrays, forms, strings} from "@atttomyx/shared-utils";
import {verbiage} from "@atttomyx/shared-constants";
import "./editCoursePage.css";

const toData = (course) => {
    return {
        name: course.name,
        imageUrl: course.imageUrl,
        status: course.status,
        valid: strings.isNotBlank(course.name) && strings.isNotBlank(course.status),
    }
};

const EditCoursePage = (props) => {
    const {
        snackbar, dimensions, match, courses, documents, videos, filters,
        onSaveCourse, onSaveDocument, onSaveVideo, onPrev, onNext
    } = props;
    const [data, setData] = useState(toData({}));
    const [course, setCourse] = useState({});
    const [saving, setSaving] = useState(false);
    const [downloading, setDownloading] = useState(null);
    const [watching, setWatching] = useState(null);
    const [deleting, setDeleting] = useState(null);
    const [showAdd, setShowAdd] = useState(false);
    const [prevId, setPrevId] = useState(null);
    const [nextId, setNextId] = useState(null);
    const paging = usePaging();

    const documentsToUse = useMemo(() => {
        const array = [];

        documents
        .filter(document => arrays.contains(document.courseIds, course.id))
        .forEach((document) => arrays.addTo(array, document));

        return array;
    }, [course, documents]);

    const videosToUse = useMemo(() => {
        const array = [];

        videos
        .filter(video => arrays.contains(video.courseIds, course.id))
        .forEach((video) => arrays.addTo(array, video));

        return array;
    }, [course, videos]);

    const items = useMemo(() => {
        const array = [];

        arrays.addAll(array, videosToUse);
        arrays.addAll(array, documentsToUse);

        return array;
    }, [documentsToUse, videosToUse]);

    const loadCourse = (courseId) => {
        const previousId = course.id;
        const filtered = filters.filter(courses);
        const found = arrays.findEntity(filtered, courseId) || {};
        const ids = arrays.getPrevNextIds(filtered, found);

        setCourse(found);
        setPrevId(ids.prevId);
        setNextId(ids.nextId);

        if (previousId && previousId !== courseId && found.id === courseId) {
            snackbar.setInfo("Loaded " + found.name);
        }
    };

    const redirectToPrev = () => {
        onPrev(prevId);
        loadCourse(prevId);
    }

    const redirectToNext = () => {
        onNext(nextId);
        loadCourse(nextId);
    }

    useEffect(() => {
        const courseId = match.params.id;

        loadCourse(courseId);
    }, []);

    useEffect(() => {
        setData(toData(course));
    }, [course]);

    const resetForm = () => {
        setData(toData(course));
    };

    const submitForm = () => {
        setSaving(true);

        const modified = {
            name: data.name,
            imageUrl: data.imageUrl,
            status: data.status,
        };

        const success = (course) => {
            setCourse(course);
            setSaving(false);

            snackbar.setSuccess("Course saved");
            onSaveCourse(course);
        };

        const failure = (err) => {
            setSaving(false);
            snackbar.setError(err);
        };

        courseService.updateCourse(course.id, modified, success, failure);
    };

    const downloadFile = (document) => {
        setDownloading(document);

        const success = (bytes) => {
            fileDownload(bytes, document.fileName);
            setDownloading(null);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setDownloading(null);
        };

        documentService.downloadFile(document.id, success, failure);
    };

    const valid = data.valid;
    const modified = forms.differ(toData(course), data);

    return saving ?
        <div className="edit-course-page">
            <CircularProgress size="80px"/>
        </div> :
        <div className="edit-course-page">
            <CourseForm
                snackbar={snackbar}
                courseId={course.id}
                data={data}
                onChange={setData}
            />
            <div className="actions">
                <IconButton color="secondary" title="Previous course"
                            disabled={!prevId}
                            onClick={redirectToPrev}>
                    <PrevIcon/>
                </IconButton>
                <IconButton color="secondary" title="Next course"
                            disabled={!nextId}
                            onClick={redirectToNext}>
                    <NextIcon/>
                </IconButton>
                <VerticalDivider/>
                <Button color="secondary" variant="text"
                        disabled={!modified}
                        onClick={resetForm}>
                    Undo
                </Button>
                <Button color="primary" size="large"
                        disabled={!valid || !modified}
                        onClick={submitForm}>
                    Save
                </Button>
            </div>
            <Divider/>
            <Cards
                label="image"
                items={items}
                paging={paging}
                dimensions={dimensions}
                renderer={(item) =>
                    item.title ?
                        <VideoCard key={item.id} video={item}>
                            <IconButton
                                color="secondary" title="Delete" className="delete"
                                onClick={() => setDeleting(item)}
                            >
                                <DeleteIcon/>
                            </IconButton>
                            <IconButton
                                color="primary" title="Watch"
                                onClick={() => setWatching(item)}
                            >
                                <WatchIcon/>
                            </IconButton>
                        </VideoCard> :
                        <DocumentCard key={item.id} document={item}>
                            <IconButton
                                color="secondary" title="Delete" className="delete"
                                onClick={() => setDeleting(item)}
                            >
                                <DeleteIcon/>
                            </IconButton>
                            {downloading && downloading.id === item.id ?
                                <CircularProgress size="24px" className="downloading"/> :
                                <IconButton
                                    color="primary" title="Download"
                                    onClick={() => downloadFile(item)}
                                >
                                    <DownloadIcon/>
                                </IconButton>}
                        </DocumentCard>}
            />
            <FloatingAddButton
                title="Add file"
                position="higher"
                disabled={courses.length < 2}
                onClick={() => {
                    setShowAdd(true);
                }}
            />
            {showAdd ?
                <AddDocumentDialog
                    snackbar={snackbar}
                    courseId={course.id}
                    courses={courses}
                    documents={documents}
                    onCancel={() => {
                        setShowAdd(false);
                    }}
                    onSaveDocument={(saved) => {
                        setShowAdd(false);
                        onSaveDocument(saved);
                    }}
                    onSaveVideo={(saved) => {
                        setShowAdd(false);
                        onSaveVideo(saved);
                    }}
                /> : null}
            {watching ?
                <YouTubeDialog
                    title={watching.title}
                    videoId={watching.youTubeId}
                    onClose={() => setWatching(null)}
                /> : null}
            {deleting ?
                <ConfirmDeleteDialog
                    snackbar={snackbar}
                    title={deleting.title ? deleting.title : deleting.fileName}
                    onCancel={() => {
                        setDeleting(null);
                    }}
                    onDelete={(itemId) => {
                        setDeleting(null);
                    }}
                    deleter={(success, failure) => {
                        const successDocument = (document) => {
                            onSaveDocument(document);
                            success(document.id);
                        };

                        const successVideo = (video) => {
                            onSaveVideo(video);
                            success(video.id);
                        };

                        if (deleting.title) {
                            videoService.removeFromCourse(course.id, deleting, successVideo, failure);

                        } else {
                            documentService.removeFromCourse(course.id, deleting, successDocument, failure);
                        }
                    }}
                /> : null}
            <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
        </div>
}

export default EditCoursePage;
