import React from "react";
import {TextField} from "@mui/material";
import {forms, objects} from "@atttomyx/shared-utils";

const VideoForm = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = modified.title && modified.youTubeId;

        onSync(modified);
    };

    return <div className="video-form">
        <div className="field top-margin">
            <TextField
                required={true}
                label="Title"
                type="text"
                value={forms.sanitizeValue(data.title)}
                onChange={(event) => onChange("title", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField
                required={true}
                label="YouTube Id"
                type="text"
                value={forms.sanitizeValue(data.youTubeId)}
                onChange={(event) => onChange("youTubeId", event.target.value)}
            />
        </div>
    </div>
}

export default VideoForm;
