import axios from "axios";
import {arrays, objects} from "@atttomyx/shared-utils";

export const listVideos = (cursor, limit, success, failure) => {
    let url = "/api/v1/video/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(err => {
        failure(err);
    });
};

export const createVideo = (video, success, failure) => {
    const url = "/api/v1/video/";

    axios.post(url, {
        title: video.title,
        youTubeId: video.youTubeId,
        courseIds: video.courseIds,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(err => {
        failure(err);
    });
};

export const addToCourse = (courseId, video, success, failure) => {
    const modified = objects.deepCopy(video);
    const addedTo = arrays.copy(video.courseIds);

    arrays.addTo(addedTo, courseId);
    modified.courseIds = addedTo;

    updateVideo(video.id, modified, success, failure);
};

export const removeFromCourse = (courseId, video, success, failure) => {
    const modified = objects.deepCopy(video);
    const removedFrom = arrays.copy(video.courseIds);

    arrays.removeFrom(removedFrom, courseId);
    modified.courseIds = removedFrom;

    updateVideo(video.id, modified, success, failure);
};

const updateVideo = (videoId, video, success, failure) => {
    const url = `/api/v1/video/${videoId}/`;

    axios.put(url, {
        title: video.title,
        youTubeId: video.youTubeId,
        courseIds: video.courseIds,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(err => {
        failure(err);
    });
};
