import React, {useEffect, useMemo} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {useBootstrap, useEntities, useFilters, useProgress, useRenderer} from "@atttomyx/shared-hooks";
import {usePushEvent} from "@atttomyx/react-hooks";
import {
    AboutPage,
    ChangePasswordPage,
    ForgotPasswordPage,
    LoginPage,
    Logo,
    NotFoundPage,
    NotificationsPage,
    PhoneStatusBar,
    ProfilePage,
    PublicHeader,
    Splash,
    UserSettingsPage,
    WelcomePage,
} from "@atttomyx/react-components";
import {UserSettingsForm} from "./components/forms";
import {Footer} from "./components/nav";
import {
    AddUserPage,
    CoursesPage,
    EditCoursePage,
    EditQuestionnairePage,
    EditUserPage,
    QuestionnairesPage,
    UsersPage
} from "./components/pages";
import {
    accountService,
    appService,
    authService,
    courseService,
    documentService,
    mobileService,
    notificationService,
    profileService,
    questionnaireService,
    questionService,
    submissionService,
    userService,
    videoService
} from "./services";
import {arrays, notifications as notificationUtils, objects, sorting, strings} from "@atttomyx/shared-utils";
import {branding as brandingUtils, firebase as firebaseUtils, mobile, router, timezones} from "@atttomyx/react-utils";
import {COURSE_FILTERS, getUserFilters, QUESTIONNAIRE_FILTERS} from "./filters";
import {themes, time} from "@atttomyx/shared-constants";
import {
    APP_NAME,
    APP_TAG_LINE,
    FIREBASE_CONFIG,
    NOTIFICATION_TYPES,
    PAGE_ABOUT,
    PAGE_COURSE,
    PAGE_COURSES,
    PAGE_HOME,
    PAGE_LOGIN,
    PAGE_NOTIFICATIONS,
    PAGE_OPTIONS,
    PAGE_PASSWORD,
    PAGE_PROFILE,
    PAGE_QUESTIONNAIRE,
    PAGE_QUESTIONNAIRES,
    PAGE_RECOVERY,
    PAGE_USER,
    PAGE_USERS,
    PAGE_WELCOME,
    PATH_DEEP_LINK,
    PRIVATE_PAGES_EXACT,
    PRIVATE_PAGES_STARTS_WITH,
    PUBLIC_PAGES_EXACT,
    PUBLIC_PAGES_STARTS_WITH,
    STATUS_ACTIVE,
    TOPICS,
    TYPE_INVENTOR
} from "./constants";
import "./app.css";
import icon from "./icon.png";

const MOCK_PHONE = false;

const sortByFileName = (a, b) => {
    return sorting.sortByField(a, b, "fileName");
};

const sortByOrder = (a, b) => {
    return sorting.sortByField(a, b, "order");
};

const App = (props) => {
    const { snackbar, dimensions, history, onThemeChange } = props;

    const users = useEntities(userService.listUsers, snackbar.setError, "users", sorting.sortByLastNameAndFirstName);
    const notifications = useEntities(notificationService.listNotifications, snackbar.setError, "notifications", sorting.sortByCreatedDesc);
    const courses = useEntities(courseService.listCourses, snackbar.setError, "courses", sorting.sortByName);
    const documents = useEntities(documentService.listDocuments, snackbar.setError, "documents", sortByFileName);
    const videos = useEntities(videoService.listVideos, snackbar.setError, "videos", sorting.sortByTitle);
    const questionnaires = useEntities(questionnaireService.listQuestionnaires, snackbar.setError, "questionnaires", sorting.sortByTitle);
    const questions = useEntities(questionService.listQuestions, snackbar.setError, "questions", sortByOrder);

    const questionnaireRenderer = useRenderer(questionnaires, (questionnaire) => questionnaire.title);

    const keyToMetadata = useMemo(() => {
        return getUserFilters(questions.entities);
    }, [questions.entities]);

    const userFilters = useFilters(keyToMetadata);
    const courseFilters = useFilters(COURSE_FILTERS);
    const questionnaireFilters = useFilters(QUESTIONNAIRE_FILTERS);

    const submissionsLoader = (cursor, limit, success, failure) => {
        const loader = bootstrap.user.roles.admin || bootstrap.user.roles.investor
            ? submissionService.listSubmissions
            : submissionService.mineSubmissions;

        loader(cursor, limit, success, failure);
    };

    const submissions = useEntities(submissionsLoader, snackbar.setError, "submissions", sorting.sortByCreatedDesc);

    const clearAll = () => {
        progress.clear();
        bootstrap.clear();
        users.clear();
        notifications.clear();
        courses.clear();
        documents.clear();
        videos.clear();
        questionnaires.clear();
        questions.clear();
        submissions.clear();
        userFilters.clear();
        courseFilters.clear();
        questionnaireFilters.clear();
    };

    const onLogout = () => {
        authService.cancelAllRequests("logged out");
        authService.clearAuthToken();
        timezones.clearTimeZone();

        clearAll();

        router.redirectToInitialPage(history, PUBLIC_PAGES_EXACT, PUBLIC_PAGES_STARTS_WITH, PAGE_LOGIN);

        if (mobile.isMobile()) {
            mobile.lifecycle(true, false);
        }
    };

    const bootstrapSuccess = () => {
        const temporaryPassword = authService.getTemporaryPassword();

        if (strings.isNotBlank(temporaryPassword)) {
            router.redirectTo(history, PAGE_PASSWORD);

        } else {
            router.redirectToInitialPage(history, PRIVATE_PAGES_EXACT, PRIVATE_PAGES_STARTS_WITH, PAGE_HOME);
        }

        notifications.refresh();
        questionnaires.refresh();
        questions.refresh();
        submissions.refresh();

        if (bootstrap.user.roles.admin || bootstrap.user.roles.investor) {
            users.refresh();

        } else {
            users.empty();
        }

        if (bootstrap.user.roles.admin || bootstrap.user.roles.inventor) {
            courses.refresh();
            documents.refresh();
            videos.refresh();

        } else {
            courses.empty();
            documents.empty();
            videos.empty();
        }

        if (mobile.isMobile()) {
            mobile.lifecycle(true, true);
        }
    };

    const bootstrapFailure = (err) => {
        snackbar.setError(err);
        onLogout();
    };

    const bootstrap = useBootstrap(authService, accountService, profileService, notificationService,
        bootstrapSuccess, bootstrapFailure);

    const progress = useProgress(bootstrap,
        [users, notifications, courses, documents, videos, questionnaires, questions, submissions],
        [],
        0,
        time.MILLISECONDS_IN_SECOND);

    const event = usePushEvent(bootstrap);

    const filteredUsers = useMemo(() => {
        return !bootstrap.user
            ? []
            : bootstrap.user.roles.admin
                ? users.entities
                : users.entities.filter(user => user.settings.type === TYPE_INVENTOR);
    }, [bootstrap.user, users.entities]);

    const filteredCourses = useMemo(() => {
        return !bootstrap.user
            ? []
            : bootstrap.user.roles.admin
                ? courses.entities
                : courses.entities.filter(course => course.status === STATUS_ACTIVE);
    }, [bootstrap.user, courses.entities]);

    const filteredQuestionnaires = useMemo(() => {
        return !bootstrap.user
            ? []
            : bootstrap.user.roles.admin
                ? questionnaires.entities
                : questionnaires.entities.filter(questionnaire => questionnaire.status === STATUS_ACTIVE);
    }, [bootstrap.user, questionnaires.entities]);

    const questionnaireIdToAskToQuestionId = useMemo(() => {
        const map = {};

        questions.entities.forEach(question => {
            const questionnaireId = question.questionnaireId;
            const askToQuestionId = objects.defaultIfNullOrUndefined(map[questionnaireId], {});

            askToQuestionId[question.ask] = question.id;
            map[questionnaireId] = askToQuestionId;
        });

        return map;
    }, [questions.entities]);

    const decoratedUsers = useMemo(() => {
        const usersById = arrays.getIdToEntity(filteredUsers);
        const decoratedById = objects.deepCopy(usersById);

        Object.values(decoratedById).forEach(decorated => {
            decorated.questions = {};
        });

        submissions.entities.forEach(submission => {
            const userId = submission.userId;

            if (userId) {
                const decorated = decoratedById[userId];
                const askToQuestionId = questionnaireIdToAskToQuestionId[submission.questionnaireId];

                if (decorated && askToQuestionId) {
                    submission.questions.forEach(question => {
                        const questionId = askToQuestionId[question.ask];

                        if (questionId) {
                            decorated.questions[questionId] = question.reply;
                        }
                    });
                }
            }
        });

        return Object.values(decoratedById).sort(sorting.sortByLastNameAndFirstName);
    }, [filteredUsers, submissions.entities, questionnaireIdToAskToQuestionId]);

    const onLogin = (accounts) => {
        progress.refresh();
        bootstrap.refresh(accounts);
    };

    useEffect(() => {
        if (event && event.type) {
            const type = event.type;
            const data = event.payload || {};
            const payload = data.payload || {};
            const actor = data.actor || {};

            console.table(type, data);

            if (bootstrap.user.id !== actor.userId) {
                switch (type) {
                    case "user_saved":
                    case "user_deleted":
                        users.refresh();
                        break;
                    default:
                        break;
                }

                if (notificationUtils.isEnabled(bootstrap.user, type)) {
                    const title = data.title;

                    if (strings.isNotBlank(title)) {
                        snackbar.setInfo(title);
                    }

                    if (notificationUtils.isPersist(bootstrap.user, type)) {
                        notifications.refresh();
                    }
                }
            }
        }
    }, [event]);

    useEffect(() => {
        const settings = bootstrap.user ? bootstrap.user.settings || {} : {};
        const theme = settings.theme || themes.LIGHT;
        const branding = brandingUtils.getBranding(theme);

        if (mobile.isMobile()) {
            mobile.setBranding(theme, branding);
        }

        onThemeChange(theme, branding);
    }, [bootstrap.account, bootstrap.user]);

    useEffect(() => {
        if (mobile.isMobile()) {
            mobile.syncProgress(progress.percent, progress.loading);
        }
    }, [progress.percent, progress.loading]);

    useEffect(() => {
        if (mobile.isMobile()) {
            mobileService.initMessagingTokenObserver(bootstrap);
        }
    }, [bootstrap]);

    useEffect(() => {
        appService.ensureOnLatestVersion(false, null);
        authService.setupAxiosInterceptors(onLogout);
        notificationUtils.initNotifications(NOTIFICATION_TYPES, TOPICS);
        firebaseUtils.initFirebase(FIREBASE_CONFIG);

        if (mobile.isMobile()) {
            mobile.initializeMessageListener();
        }

        if (authService.loggedIn()) {
            onLogin();

        } else {
            onLogout();
        }

        return () => {
            if (mobile.isMobile()) {
                mobile.deactivateMessageListener();
            }
        }
    }, []);

    return <div className="app">
        {progress.loading || progress.paused ?
            <Splash
                percent={progress.percent}
            /> : bootstrap.account && bootstrap.user ?
            <>
                {MOCK_PHONE ? <PhoneStatusBar/> : null}
                <div className="content">
                    <Switch>
                        <Route exact path="/">
                            <Redirect to={PAGE_HOME}/>
                        </Route>
                        <Route path={PAGE_NOTIFICATIONS}>
                            <NotificationsPage
                                snackbar={snackbar}
                                dimensions={dimensions}
                                notifications={notifications.entities}
                                onBulkSave={notifications.onEntitiesSaved}
                                onDelete={notifications.onEntityDeleted}
                                notificationService={notificationService}
                            />
                        </Route>
                        {bootstrap.user.roles.admin || bootstrap.user.roles.inventor ?
                            <Route path={PAGE_COURSES}>
                                <CoursesPage
                                    snackbar={snackbar}
                                    dimensions={dimensions}
                                    user={bootstrap.user}
                                    courses={filteredCourses}
                                    documents={documents.entities}
                                    videos={videos.entities}
                                    filters={courseFilters}
                                    onSave={courses.onEntitySaved}
                                    onCopy={(result) => {
                                        courses.onEntitySaved(result.course);
                                        documents.onEntitiesSaved(result.documents);
                                        videos.onEntitiesSaved(result.videos);
                                    }}
                                    onDelete={courses.onEntityDeleted}
                                />
                            </Route> : null}
                        {bootstrap.user.roles.admin ?
                            <Route
                                path={`${PAGE_COURSE}/:id`}
                                render={(props) =>
                                    <EditCoursePage
                                        {...props}
                                        snackbar={snackbar}
                                        dimensions={dimensions}
                                        courses={courses.entities}
                                        documents={documents.entities}
                                        videos={videos.entities}
                                        filters={courseFilters}
                                        onSaveCourse={courses.onEntitySaved}
                                        onSaveDocument={documents.onEntitySaved}
                                        onSaveVideo={videos.onEntitySaved}
                                        onPrev={(courseId) => router.redirectTo(history, PAGE_COURSE + "/" + courseId)}
                                        onNext={(courseId) => router.redirectTo(history, PAGE_COURSE + "/" + courseId)}
                                    />}
                            /> : null}
                        <Route path={PAGE_QUESTIONNAIRES}>
                            <QuestionnairesPage
                                snackbar={snackbar}
                                dimensions={dimensions}
                                user={bootstrap.user}
                                questionnaires={filteredQuestionnaires}
                                questions={questions.entities}
                                submissions={bootstrap.user.roles.inventor ? submissions.entities : []}
                                filters={questionnaireFilters}
                                onSaveQuestionnaire={questionnaires.onEntitySaved}
                                onCopyQuestionnaire={(result) => {
                                    questionnaires.onEntitySaved(result.questionnaire);
                                    questions.onEntitiesSaved(result.questions);
                                }}
                                onDeleteQuestionnaire={questionnaires.onEntityDeleted}
                                onSaveSubmission={submissions.onEntitySaved}
                                onDeleteSubmission={submissions.onEntityDeleted}
                            />
                        </Route>
                        {bootstrap.user.roles.admin ?
                            <Route
                                path={`${PAGE_QUESTIONNAIRE}/:id`}
                                render={(props) =>
                                    <EditQuestionnairePage
                                        {...props}
                                        snackbar={snackbar}
                                        dimensions={dimensions}
                                        questionnaires={questionnaires.entities}
                                        questions={questions.entities}
                                        filters={questionnaireFilters}
                                        onSaveQuestionnaire={questionnaires.onEntitySaved}
                                        onSaveQuestion={questions.onEntitySaved}
                                        onDeleteQuestion={questions.onEntityDeleted}
                                        onPrev={(questionnaireId) => router.redirectTo(history, PAGE_QUESTIONNAIRE + "/" + questionnaireId)}
                                        onNext={(questionnaireId) => router.redirectTo(history, PAGE_QUESTIONNAIRE + "/" + questionnaireId)}
                                    />}
                            /> : null}
                        {bootstrap.user.roles.admin || bootstrap.user.roles.investor ?
                            <Route exact path={PAGE_USERS}>
                                <UsersPage
                                    snackbar={snackbar}
                                    dimensions={dimensions}
                                    user={bootstrap.user}
                                    users={decoratedUsers}
                                    filters={userFilters}
                                    questionnaires={filteredQuestionnaires}
                                    questions={questions.entities}
                                    submissions={submissions.entities}
                                    questionnaireRenderer={questionnaireRenderer}
                                    onDelete={users.onEntityDeleted}
                                    onGotoAdd={() => router.redirectTo(history, PAGE_USER)}
                                    onGotoProfile={() => router.redirectTo(history, PAGE_PROFILE)}
                                    onGotoUser={(userId) => router.redirectTo(history, `${PAGE_USER}/${userId}`)}
                                    userService={userService}
                                />
                            </Route> : null}
                        {bootstrap.user.roles.admin ?
                            <Route exact path={PAGE_USER}>
                                <AddUserPage
                                    snackbar={snackbar}
                                    onSave={(user) => {
                                        users.onEntitySaved(user);
                                        router.redirectTo(history, PAGE_USER + "/" + user.id);
                                    }}
                                    userService={userService}
                                />
                            </Route> : null}
                        {bootstrap.user.roles.admin ?
                            <Route
                                path={`${PAGE_USER}/:id`}
                                render={(props) =>
                                    <EditUserPage
                                        {...props}
                                        snackbar={snackbar}
                                        user={bootstrap.user}
                                        users={decoratedUsers}
                                        filters={userFilters}
                                        onSave={users.onEntitySaved}
                                        onPrev={(userId) => router.redirectTo(history, PAGE_USER + "/" + userId)}
                                        onNext={(userId) => router.redirectTo(history, PAGE_USER + "/" + userId)}
                                        userService={userService}
                                        showImage={true}
                                    />}
                            /> : null}
                        <Route path={PAGE_PROFILE}>
                            <ProfilePage
                                snackbar={snackbar}
                                user={bootstrap.user}
                                onSave={bootstrap.syncProfile}
                                profileService={profileService}
                                showImage={true}
                                showBirthday={true}
                            />
                        </Route>
                        <Route path={PAGE_OPTIONS}>
                            <UserSettingsPage
                                snackbar={snackbar}
                                user={bootstrap.user}
                                onSaveProfile={bootstrap.syncProfile}
                                onSaveNotifications={bootstrap.syncNotifications}
                                profileService={profileService}
                                notificationService={notificationService}
                                settingsForm={UserSettingsForm}
                                allowApp={true}
                                allowEmail={true}
                            />
                        </Route>
                        <Route path={PAGE_PASSWORD}>
                            <ChangePasswordPage
                                snackbar={snackbar}
                                authService={authService}
                                onChanged={() => router.redirectTo(history, PAGE_HOME)}
                            />
                        </Route>
                        <Route path={PAGE_ABOUT}>
                            <AboutPage
                                appName={APP_NAME}
                                appDescription={APP_TAG_LINE}
                                appService={appService}
                                user={bootstrap.user}
                                custom={<Logo logoUrl={icon}/>}
                            />
                        </Route>
                        <Route path={PAGE_LOGIN}>
                            <Redirect to={PAGE_HOME}/>
                        </Route>
                        <Route>
                            <NotFoundPage/>
                        </Route>
                    </Switch>
                </div>
                <Footer
                    user={bootstrap.user}
                    onLogout={onLogout}
                    numNotifications={notifications.entities.filter(notificationUtils.isNewNotification).length}
                />
            </> :
            <>
                <PublicHeader
                    dimensions={dimensions}
                    appName={APP_NAME}
                    src={icon}
                />
                <div className="content">
                    <Switch>
                        <Route path={PAGE_LOGIN}>
                            <LoginPage
                                snackbar={snackbar}
                                authService={authService}
                                onForgotPassword={() => router.redirectTo(history, PAGE_RECOVERY)}
                                onLogin={onLogin}
                            />
                        </Route>
                        <Route path={PAGE_RECOVERY}>
                            <ForgotPasswordPage
                                snackbar={snackbar}
                                authService={authService}
                                onBackToLogin={() => router.redirectTo(history, PAGE_LOGIN)}
                                onLogin={onLogin}
                                appName={APP_NAME}
                            />
                        </Route>
                        <Route path={`${PATH_DEEP_LINK}${PAGE_WELCOME}`}>
                            <WelcomePage
                                snackbar={snackbar}
                                authService={authService}
                                onToLogin={() => router.redirectTo(history, PAGE_LOGIN)}
                                onLogin={onLogin}
                            />
                        </Route>
                    </Switch>
                </div>
            </>}
        </div>
}

export default withRouter(App);
